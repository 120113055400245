<template>
  <div class="page-login">
    <c-auth-modal title="Восстановление" :loading="$var('load')" back>
      <template #sub>
        На указанный вами телефон отправлен одноразовый пороль введите его
      </template>
      <n-form @submit="submit">
        <n-items>
          <n-input title="Введите телефон" :value="login" text />
          <n-input title="Введите код" v-bind="$form.input('code')" />
          <n-button wide color="primary" type="submit">Далее</n-button>
        </n-items>
      </n-form>
    </c-auth-modal>
  </div>
</template>

<script>
export default {
  name: 'PageForgot2',
  computed: {
    login() {
      return this.$route.query.login
    },
  },
  created() {
    this.$form.init({
      login: this.login,
      code: '',
    })
    this.$form.rules({
      code: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.auth.loginWithCode(this.$form.get()).then((response) => {
          const data = response.data.content
          $app.auth.login(data.user, data.token, data.grants, false)
          this.$router.push({ name: 'auth.forgot3', })
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-login {

}
</style>
